import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Header from "./Components/Header";
import Footer from "./Components/Footer";
import About from "./Components/About";
import Resume from "./Components/Resume";
import Contact from "./Components/Contact";
import Testimonials from "./Components/Testimonials";
import Portfolio from "./Components/Portfolio";
import CarloEditor from "./Components/admin/CarloEditor";

import "./App.css";

const App = () => {
    const [resumeData, setResumeData] = useState({});

    useEffect(() => {
        fetch("/resumeData.json")
            .then((res) => res.json())
            .then((data) => {
                setResumeData(data);
            });
    }, []);

    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route
                        path="/"
                        element={
                            <>
                                <Header data={resumeData.main} />
                                <About data={resumeData.main} />
                                <Resume data={resumeData.resume} />
                                <Portfolio data={resumeData.portfolio} />
                                <Testimonials data={resumeData.testimonials} />
                                <Contact data={resumeData.main} />
                                <Footer data={resumeData.main} />
                            </>
                        }
                    />
                    <Route path="/carlo" element={<CarloEditor />} />
                </Routes>
            </div>
        </Router>
    );
};

export default App;
