import React, { useState, useEffect } from "react";
import "./CarloEditor.css";

const CarloEditor = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch("/resumeData.json")
            .then((res) => res.json())
            .then((fetchedData) => {
                setData(fetchedData);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setLoading(false);
            });
    }, []);

    const handleChange = (path, value) => {
        const updatedData = { ...data };
        let current = updatedData;
        for (let i = 0; i < path.length - 1; i++) {
            current = current[path[i]];
        }
        current[path[path.length - 1]] = value;
        setData(updatedData);
    };

    const addItemToArray = (path) => {
        const updatedData = { ...data };
        let current = updatedData;

        // Naviga fino al livello desiderato
        for (let i = 0; i < path.length - 1; i++) {
            current = current[path[i]];
        }

        const array = current[path[path.length - 1]];

        // Modello per il nuovo oggetto, basato sul primo elemento o predefinito
        const newItem = Array.isArray(array) && array.length > 0
            ? Object.keys(array[0]).reduce((acc, key) => {
                acc[key] = ""; // Inizializza i campi come stringhe vuote
                return acc;
            }, {})
            : {}; // Se l'array è vuoto, crea un oggetto vuoto

        array.push(newItem); // Aggiungi il nuovo oggetto all'array
        setData(updatedData);
    };

    const removeItemFromArray = (path, index) => {
        const updatedData = { ...data };
        let current = updatedData;
        for (let i = 0; i < path.length - 1; i++) {
            current = current[path[i]];
        }
        current[path[path.length - 1]].splice(index, 1);
        setData(updatedData);
    };

    const renderField = (key, value, path) => {
        if (typeof value === "string") {
            return (
                <div className="field-group" key={path.join(".")}>
                    <label>{key}:</label>
                    <input
                        type="text"
                        value={value}
                        onChange={(e) => handleChange(path, e.target.value)}
                    />
                </div>
            );
        }

        if (typeof value === "number") {
            return (
                <div className="field-group" key={path.join(".")}>
                    <label>{key}:</label>
                    <input
                        type="number"
                        value={value}
                        onChange={(e) => handleChange(path, Number(e.target.value))}
                    />
                </div>
            );
        }

        if (Array.isArray(value)) {
            return (
                <div className="object-container" key={path.join(".")}>
                    <h2>{key}</h2>
                    {value.map((item, index) => (
                        <div className="array-item" key={index}>
                            {typeof item === "object"
                                ? Object.keys(item).map((subKey) =>
                                    renderField(subKey, item[subKey], [...path, index, subKey])
                                )
                                : renderField(`${key}[${index}]`, item, [...path, index])}
                            <div className="array-controls">
                                <button
                                    className="remove"
                                    onClick={() => removeItemFromArray(path, index)}
                                >
                                    Remove
                                </button>
                            </div>
                        </div>
                    ))}
                    <button onClick={() => addItemToArray(path)}>Add Item</button>
                </div>
            );
        }

        if (typeof value === "object" && value !== null) {
            return (
                <div className="object-container" key={path.join(".")}>
                    <h2>{key}</h2>
                    {Object.keys(value).map((subKey) =>
                        renderField(subKey, value[subKey], [...path, subKey])
                    )}
                </div>
            );
        }

        return null;
    };

    const handleSave = () => {
        fetch("https://www.carlocentofanti.it/carlo", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data), // Invia i dati aggiornati come stringa JSON
        })
            .then((response) => {
                if (response.ok) {
                    alert("Data saved successfully!");
                } else {
                    alert("Failed to save data.");
                }
            })
            .catch((error) => {
                console.error("Error saving data:", error);
                alert("An error occurred while saving the data.");
            })
            .finally(() =>{
                console.log(JSON.stringify(data));
                console.log(data)
            });
    };


    if (loading) return <div>Loading...</div>;

    return (
        <div className="editor-container">
            <h1 id="title">Edit JSON</h1>
            {Object.keys(data).map((key) => renderField(key, data[key], [key]))}
            <button onClick={handleSave}>Save Changes</button>
        </div>
    );
};

export default CarloEditor;
